// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByp80xOEDk1DJnD5j915WyZrGS-ATu8Y0",
  authDomain: "calendar-share-6d5fb.firebaseapp.com",
  projectId: "calendar-share-6d5fb",
  storageBucket: "calendar-share-6d5fb.appspot.com",
  messagingSenderId: "538609666382",
  appId: "1:538609666382:web:df8cebbda30012e635f61f",
  measurementId: "G-FSRR6R57ZE"
};
export default firebaseConfig;